<template>
  <div id="my-saved-items" class="account-container container">
    <ProfileNav />

    <div class="header">
      <h1>MY SAVED ITEMS ({{ saved.length }})</h1>
    </div>

    <div class="saved-items">
      <md-table class="no-saved-items cart-table" v-show="!saved.length">
        <md-table-row>
          <md-table-head>Release</md-table-head>
          <md-table-head>Song Title</md-table-head>
          <md-table-head>Units</md-table-head>
          <md-table-head>Config</md-table-head>
          <md-table-head>Song Code</md-table-head>
          <md-table-head>Artist</md-table-head>
          <md-table-head>Playtime</md-table-head>
          <md-table-head>Royalty Rate</md-table-head>
          <md-table-head>Royalty Amount</md-table-head>
          <md-table-head>Processing Fee</md-table-head>
          <md-table-head>Remove</md-table-head>
          <md-table-head>Add to Cart</md-table-head>
        </md-table-row>

        <md-table-row>
          <md-table-cell colspan="12">No saved items.</md-table-cell>
        </md-table-row>
      </md-table>

      <md-table v-model="saved" class="cart-table" v-show="saved.length">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Release" md-sort-by="release">
            {{ item.release }}
          </md-table-cell>

          <md-table-cell md-label="Song Title">
            {{ item.songTitle }}
          </md-table-cell>

          <md-table-cell md-label="Units" class="units">
            {{ item.units }}
          </md-table-cell>

          <md-table-cell md-label="Config" class="config">
            {{ item.config.substring(0, 4) }}
          </md-table-cell>

          <md-table-cell md-label="Song Code">
            {{ item.songCode }}
          </md-table-cell>

          <md-table-cell md-label="Artist">
            {{ item.artist }}
          </md-table-cell>

          <md-table-cell md-label="Playtime">
            {{ item.playtime }}
          </md-table-cell>

          <md-table-cell md-label="Royalty Rate">
            {{ item.royaltyFee | numeral("$0,0.0000") }}
          </md-table-cell>

          <md-table-cell md-label="Royalty Amount">
            {{ item.royaltyAmount | numeral("$0,0.00") }}
          </md-table-cell>

          <md-table-cell md-label="Processing Fee">
            {{ item.processingFee | numeral("$0,0.00") }}
          </md-table-cell>

          <md-table-cell md-label="Remove">
            <md-button
              class="md-raised md-accent md-icon-button delete"
              @click="removeReleaseItem(item)"
            >
              <md-icon>delete</md-icon>
            </md-button>
          </md-table-cell>

          <md-table-cell md-label="Add to Cart">
            <md-button
              class="md-raised md-primary md-icon-button"
              @click="addToCart(item)"
            >
              <md-icon>shopping_cart</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ProfileNav from "@/components/ProfileNav.vue";

export default {
  name: "MySavedItems",
  components: {
    ProfileNav
  },
  metaInfo: function() {
    return {
      title: " - My Saved Items",
      meta: [
        {
          name: "description",
          content: "Items you saved for later."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  data: function() {
    return {};
  },
  computed: {
    saved: function() {
      return this.$store.getters["release/getAllSavedItems"];
    }
  },
  methods: {
    ...mapActions("release", ["removeReleaseItem"]),
    addToCart: function(item) {
      this.$store.dispatch("release/setItemFlag", {
        item: item,
        flag: "cart"
      });
    }
  }
};
</script>
<style lang="scss">
#my-saved-items {
}
</style>
